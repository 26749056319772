import de from '~/lib/locale/de/de.ts';

export const supportedLngs = ['de', 'en'];

export const fallbackLng = 'de';

export const defaultNS = 'common';

export const resources = {
  de: {
    common: de,
  },
  en: {
    common: {},
  },
};
